import * as Types from './types'

const setPageData = (data) => dispatch => {
	dispatch({
		type: Types.SET_PAGE_DATA,
		payload: {
			data: data,
			error: {}
		}
	})
}

export default setPageData