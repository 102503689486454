import {
	clearTimeLog,
	retrieveTimeLog
} from "./timeLogActions";
import {
	clearTask,
	retrieveTask
} from "./taskActions";
import {
	getUserCompanies,
} from "./userActions";
import {asyncLocalStorage as awaitLocalStorage} from "../utils/asyncLocalStorage";

export const setCommonData = (data) => async (dispatch) => {
	return true;
};

export const setDefaultUserData = (data) => async (dispatch) => {
	dispatch(retrieveTask());
	await dispatch(getUserCompanies());
	console.log('company data ready')
	await dispatch(retrieveTimeLog());
	return true;
};
export const afterSwitchCompany = (data) => async (dispatch) => {
	dispatch(clearTask());
	dispatch(clearTimeLog());
	dispatch(retrieveTimeLog());
	return true;
};