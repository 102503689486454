import React from 'react'
import { Helmet } from 'react-helmet'
import {
    Link,
    useNavigate
} from 'react-router-dom'

import { connect } from 'react-redux'
import {
    userRegistrationAction
} from '../../store/actions/authActions'
import bodyClassController from '../../store/utils/bodyClassController'
import setPageData from '../../store/actions/setPageData'

// Style assets
// import '../../assets/styles/auth.scss'
import imgLogo from '../../assets/images/logo.png'

class RegistrationPageDtl extends React.Component {
    state = {
        user: '',
        password: '',
        remember: false,
        error: {},
        isLoading: false,
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if (JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)) {
            return {
                error: nextProps.auth.error
            }
        }
        return null
    }

    changeHandler = event => {
        let stateName, stateValue;
        if (event.target.type === 'checkbox') {
            stateName = event.target.name;
            stateValue = event.target.checked;
        } else {
            stateName = event.target.name;
            stateValue = event.target.value;
        }


        this.setState({
            [stateName]: stateValue
        })
    }

    stopLoading = () => {
        this.setState({ isLoading: false })
    }

    submitHandler = async event => {
        event.preventDefault()

        this.setState({isLoading: true})

        let {
            name,
            email,
            password
        } = this.state
        let insertInfo = await this.props.userRegistrationAction({
            name,
            email,
            password
        })
        this.setState({isLoading: false})

        if (insertInfo) {
            this.props.navigate("/login");
            // this.props.history.push('/login')
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.props.setPageData({
            title: 'Registration',
            pageID: 'registration'
        });

    }
    render() {
        this.props.bodyClassController(this.props.pageData);
        let { name, email, password, error, isLoading } = this.state
        return (
            <>
                <Helmet> <title>{'Login here'}</title> </Helmet>

                <div className="container-fluid">
                    <div className="row page-auth">
                        <div className="col-md-4 page-content">
                            <div className="row">
                                <div className="col-md-12 login-content">
                                    <div className="auth-brand">
                                        <img src={imgLogo} className="logo" alt="Logo" />
                                    </div>

                                    <p className="text-center">Join Task Planner</p>
                                    <form onSubmit={this.submitHandler} className="">
                                        <div className="form-group has-error">
                                            <input
                                                type="text"
                                                className={
                                                    (error.name ? "form-control is-invalid" : "form-control")
                                                }
                                                placeholder="Name"
                                                autoFocus={true}
                                                name="name"
                                                id="name"
                                                tabIndex='1'
                                                onChange={this.changeHandler}
                                                value={name}
                                                required
                                            />
                                            {error.name && (
                                                <div className="invalid-feedback">
                                                    {error.name}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group has-error">
                                            <input
                                                type="text"
                                                className={
                                                    (error.email ? "form-control is-invalid" : "form-control")
                                                }
                                                placeholder="Email"
                                                name="email"
                                                id="email"
                                                tabIndex='2'
                                                onChange={this.changeHandler}
                                                value={email}
                                                required
                                            />
                                            {error.email && (
                                                <div className="invalid-feedback">
                                                    {error.email}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className={
                                                    (error.password ? "form-control is-invalid" : "form-control")
                                                }
                                                placeholder="Password"
                                                name="password"
                                                id="password"
                                                tabIndex='3'
                                                onChange={this.changeHandler}
                                                value={password}
                                                required
                                            />
                                            {error.password && (
                                                <div className="invalid-feedback">
                                                    {error.password}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group form-support">
                                            <div className="form-check remember-me">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    tabIndex='4'
                                                    name="remember"
                                                    onChange={this.changeHandler}
                                                    required="true"
                                                    id="defaultCheck1" />

                                                <label className="form-check-label text-muted" htmlFor="defaultCheck1">
                                                    Accepts terms
                                                </label>
                                            </div>
                                        </div>

                                        <div className="text-right">
                                            <button
                                                type={isLoading ? 'button' : 'submit'}
                                                tabIndex='4'
                                                className={
                                                    (error.error ? "btn btn-primary mb-2 px-5 btn-block is-invalid" : "btn btn-primary mb-2 px-5 btn-block")
                                                }> {isLoading ? (<i className="fas fa-spinner fa-spin"/>) : 'SIGNUP'} </button>

                                            {error.error && (
                                                <div className="invalid-feedback text-center">
                                                    {error.error}
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            Already has account? {" "}<Link to="/login" className="">Login here</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 preview-section">
                            <h1>TASK PLANNER</h1>
                            <p>Best way to utilize your time</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pageData: state.pageData,
})

const mapDispatchToProps = ({
    userRegistrationAction,
    setPageData,
    bodyClassController
})
// export default connect( mapStateToProps, {login} )(Login)

function RegistrationPage(props) {
    let navigate = useNavigate();
    return <RegistrationPageDtl {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage)