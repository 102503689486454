import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {
	logout,
	logoutAction
} from '../store/actions/authActions'


import bodyClassController from '../store/utils/bodyClassController'

import setPageData from '../store/actions/setPageData'
import CreateNewTask from "./task/CreateNewTask";
import {retrieveTask, retrieveTaskByParent, setParentTaskAction} from "../store/actions/taskActions";
import ShowTasks from "./task/ShowTasks";
import ShowTimeLog
	from "./timeLog/ShowTimeLog";
import {useNavigate} from "react-router-dom";

class HomePageDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}


	setParentTaskHandler = async (parentTaskID) => {
		let {taskData} = this.props
		let allTask;
		if(taskData){
			allTask = taskData.data.length?taskData.data:null
		}

		let taskState = this.props.taskData;

		this.setState({
			parentTaskID: parentTaskID,
			parentTaskIDTemp: parentTaskID
		}, () => {
			this.props.retrieveTaskByParent({
				parent: parentTaskID
			}, taskState)
		})
	}

	changeHandler = async (event) => {
		let stateName, stateValue;
		if (event.target.type == 'checkbox') {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}


		this.setState({
			[stateName]: stateValue
		}, () => {
			console.log('type', event.target.type);
			if (event.target.type === 'checkbox' || event.target.type === 'select-one') {
				this.submitHandler()
			}
		})
	}


	retrieveTasks = async (event) => {
		if (event != null) {
			event.preventDefault()
		}

		this.setState({
			dataLoading: true
		})

		let {parentTaskID, parentTaskIDTemp} = this.state

		parentTaskID = parentTaskIDTemp ? parentTaskIDTemp :parentTaskID


		let taskState = this.props.taskData;
		await this.props.retrieveTaskByParent({
			parent: parentTaskID?parentTaskID:0
		}, taskState)

		this.setState({
			dataLoading: false
		})
	}

	setPageData() {
		this.props.setPageData({
			title: 'Home',
			pageID: 'home_page'
		});
	}

	componentDidMount() {
		this.setPageData()
		this.retrieveTasks()
	}
	componentWilMount() {
	}
	componentDidUpdate(prevProps, prevState, snapshot) {

		let {taskData} = this.props

		console.group('parentTask')
		console.log(taskData.parentTask)
		console.groupEnd()
	}



	handleSelectChange = (newValue, actionMeta) => {
		this.setParentTaskHandler(newValue.value);

		this.setState({
			[actionMeta.name]: newValue.value,
		});
	};
	getTasksAsOptions = async (inputValue) => {
		let {taskData} = this.props
		let allTask;
		if(taskData){
			allTask = taskData.data.length?taskData.data:null
		}


		let options = [{ value: 0, label: "Main Tasks" }];
		for (const i of allTask) {
			options.push({ value: i.id, label: i.title });
		}

		let finalOptions;
		if (inputValue) {
			finalOptions = options.filter((s) =>
				s.label.toUpperCase().includes(inputValue.toUpperCase())
			);
		} else {
			finalOptions = options;
		}

		return finalOptions;
	};

	render() {
		this.props.bodyClassController(this.props.pageData)

		let {taskData, auth} = this.props
		let allTask, rootTask, taskPagination, parentTaskID, parentTask;
		if(taskData){
			allTask = taskData.data.length?taskData.data:null
			parentTask = taskData.parentTask.length?taskData.parentTask:null
		}

		// Select2 custom styling
		const customStyles = {
			control: (base, state) => ({
				...base,
				background: "transparent",
				color: "white",
				// match with the menu
				// borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
				// Overwrittes the different states of border
				borderColor: state.isFocused ? "#ffffff78" : "#ffffff38",
				// Removes weird border around container
				boxShadow: state.isFocused ? null : null,
				"&:hover": {
					// Overwrittes the different states of border
					borderColor: state.isFocused ? "#ffffff78" : "#ffffff38"
				}
			}),
			menu: base => ({
				...base,
				// override border radius to match the box
				borderRadius: 0,
				// kill the gap
				marginTop: 0,
				background: "#193744",
				color: "white",
			}),
			menuList: base => ({
				...base,
				// kill the white space on first and last option
				padding: 0,
				color: "white",
			}),
			singleValue: (provided) => ({
			...provided,
					color: 'white'
			}),
			option: provided => ({
				...provided,
				color: 'white'
			}),
		};

		return (
			<div>
				<nav className={`nav`}>
					<div className="container">
						<div className="task-switcher">
							{allTask
								? <AsyncSelect
									cacheOptions
									defaultOptions
									placeholder={`Select task...`}
									name="parentTaskID"
									id="parentTaskID"
									className="switcher"
									loadOptions={this.getTasksAsOptions}
									onChange={this.handleSelectChange}
									styles={customStyles}
									// menuIsOpen={true}
								/>
								: ''}
						</div>
						<div className="branding">
							<div className="branding-logo"/>
							<div className="branding-title">TASK PLANNER</div>
						</div>
						<div className="user-info">
							<div className="name-details">
								<div className="company">{this.props.user.companies.selected.name}</div>
								<div className="name">{auth.user.name}</div>
							</div>
						</div>
					</div>
				</nav>

				<Tabs className="section-switcher" selectedTabClassName="active">
					<TabList className="section-header">
						<Tab className="header-item">
							<i className="icon fas fa-home"/>
							Task board
						</Tab>
						<Tab className="header-item">
							<i className="icon fas fa-print"/>
							Report
						</Tab>
						<Tab className="header-item" onClick={() => {
							this.props.navigate('switch-company');
						}}>
							<i className="icon fas fa-arrows-alt-h"/>
							Switch
						</Tab>
						<Tab className="header-item text-danger" onClick={() => {
							this.props.logoutAction(this.props.navigate)
						}}>
							<i className="icon fas fa-sign-out-alt"/>
							Logout
						</Tab>
					</TabList>
					<TabPanel className="user-dashboard">
						<div className="page-section">
							<div className="container">
								<Tabs selectedTabClassName="active">
									<TabList className="task-header">
										<Tab className="header-item">Explore task</Tab>
										<Tab className="header-item">Task List</Tab>
									</TabList>

									<TabPanel className="task-space">
										{ parentTask ? Object.keys(parentTask).map((item, index) => {
											let parentTaskID = parentTask[index]
											return <ShowTasks key={`show_task_${parentTaskID}`}
															  taskIndex={index}
															  parentTaskID={parentTaskID} className={"type-parent"}/>
										}):<ShowTasks key={`show_task_0`}
													  taskIndex={0}
													  parentTaskID={0} className={"type-parent"}/> }
									</TabPanel>
									<TabPanel className="task-space">
										<ShowTasks key={`show_task_all`}
												   taskIndex={0}
												   parentTaskID="all" className={"type-parent"}/>
									</TabPanel>
								</Tabs>
							</div>
						</div>

						<div className="right-section">
							<div className="single-widget">
								<ShowTimeLog className="time-log-section"/>
							</div>
						</div>
					</TabPanel>
					<TabPanel className="task-list coming-soon">
						<div className="">
							<h2>Task List</h2>
						</div>
					</TabPanel>
				</Tabs>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	user: state.user,
	pageData: state.pageData,
	userData: state.userData,
	taskData: state.taskData,
})
const mapDispatchToProps = ({
	logoutAction,
	setPageData,
	bodyClassController,
	retrieveTaskByParent,
	setParentTaskAction,
})

function HomePage(props) {
	let navigate = useNavigate();
	return <HomePageDtl {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)