import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import {TIME_LOG_CLEAR} from "./types";


export const retrieveTimeLog =
	(postData) => (dispatch) => {
		axios
			.get("/time-log", {params: postData})
			.then((res) => {
				let responseData = res.data;
				if(responseData.data){
					dispatch({
						type: Types.TIME_LOG_RETRIEVE,
						payload: responseData,
					});
				}
			})
			.catch((error) => {
				manageError(error);
			});
	};

export const updateTimeLogAction =
	(postData) => (dispatch) => {
		axios
			.put("/time-log", postData)
			.then(async (res) => {
				// let responseData = res.data;
				await dispatch(retrieveTimeLog());
			})
			.catch((error) => {
				manageError(error);
			});
	};


export const clearTimeLog =
	(postData) => (dispatch) => {
		dispatch({
			type: Types.TIME_LOG_CLEAR,
			payload: {},
		});
	};
