import axios from "axios";
import * as Types from "./types";
import store from './../index';

import { manageError } from "../utils/axiosResponseError";
import {
	clearTask,
	retrieveTaskByParent
} from "./taskActions";
import {clearTimeLog} from "./timeLogActions";
import {afterSwitchCompany} from "./basicActions";
import configureAxios
	from "../utils/configureAxios";
import {
	asyncLocalStorage
} from "../utils/asyncLocalStorage";
import {USER_COMPANIES_SELECTED} from "./types";


export const getUserCompanies =
	(postData) => async (dispatch) => {
		return await axios
			.get("/user/companies", {params: postData})
			.then(async (res) => {
				let responseData = res.data;
				if (responseData) {
					dispatch({
						type: Types.USER_COMPANIES_GET,
						payload: responseData,
					});

					// Set selected company
					let companyID = await asyncLocalStorage.getItem("companyID");
					if(companyID){
						responseData.map(function(selectedCompany){
							if(companyID == selectedCompany.id) {
								dispatch({
									type: Types.USER_COMPANIES_SELECTED,
									payload: selectedCompany,
								});
							}
						})

					}
				}
				return true;
			})
			.catch((error) => {
				manageError(error);
			});
	};

export const setUserCompany = (postData) => async (dispatch) => {
	// Set id to session
	await asyncLocalStorage.setItem("companyID", postData.id);
	await configureAxios(); // Set token to axios request header configure
	await dispatch(afterSwitchCompany());

	dispatch({
		type: Types.USER_COMPANIES_SELECTED,
		payload: postData,
	});

	return true;
};
