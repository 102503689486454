import { showSuccess, showError } from './configToast'

export const manageError = (err) => {
	let errorMsg;
	if (err.response) {
		console.log('err.response', Object.values(err.response.data))
		try {
			errorMsg = Object.values(err.response.data).join(', ')
		} catch (error) {
			errorMsg = 'Server error'
		}
	} else if (err.request) {
		errorMsg = 'Network error'
		// client never received a response, or request never left
	} else {
		// anything else
	}

	showError(errorMsg);
}