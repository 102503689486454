import * as Types from "../actions/types";

const init = {
	data: [],
	pagination: {},
	error: {},
};

let timeLogReducer = (state = init, action) => {
	switch (action.type) {
		case Types.TIME_LOG_RETRIEVE: {
			return {
				...state,
				data: action.payload.data,
				pagination: action.payload.pagination,
				error: {},
			};
		}
		case Types.TIME_LOG_CLEAR: {
			return init;
		}

		default:
			return state;
	}
};

export default timeLogReducer;
