import React from "react";
import ReactDOM from "react-dom";

import './assets/styles/styles.css';

import * as serviceWorker from "./serviceWorker";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./store";
import MyRoutes
	from "./Routes";
import {
	Route,
	Router
} from "react-router-dom";

ReactDOM.render(
	<Provider store={store}>
		{/*<Router>*/}
		{/*	<Route path="/" exact={true} element=<LoginPage/> />*/}
		{/*</Router>*/}
		<MyRoutes/>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			pauseOnFocusLoss={false}
			draggable
			pauseOnHover
		/>
		<ToastContainer />
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
