import React from "react";
import { connect } from "react-redux";
import {isLoggedin} from "../store/actions/authActions";
import configureAxios
	from "../store/utils/configureAxios";
import bodyClassController
	from "../store/utils/bodyClassController";
import setPageData
	from "../store/actions/setPageData";
import {useNavigate} from "react-router-dom";

class AuthCheckingDtl extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	async authCheck() {
		await configureAxios(); // Run Basic Axios Configure
		await this.props.isLoggedin(this.forceLogin, this.forceSwitchCompany);
	}
	forceLogin = () => {
		let { isAuthenticated, authChecking } = this.props.auth;
		if (!authChecking && !isAuthenticated) {
			this.props.navigate("login");
			// this.props.history.push("/login");
		}
	};
	forceSwitchCompany = () => {
		this.props.navigate("switch-company");
	};
	componentDidMount() {
		this.authCheck();
		this.props.setPageData({
			title: 'Splash',
			pageID: 'splash'
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData);

		return (
			<div className="page-auth-checking">
				<div className="checking-content">
					<div className="footer">
						<i className="fas fa-spinner fa-spin"/> Loading...
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	user: state.user,
});
const mapDispatchToProps = {
	isLoggedin,
	setPageData,
	bodyClassController
};
function AuthChecking(props) {
	let navigate = useNavigate();
	return <AuthCheckingDtl {...props} navigate={navigate} />
}


export default
connect(mapStateToProps, mapDispatchToProps)(AuthChecking)
