import React from "react";
import {
	BrowserRouter,
	Route,
	Router,
	Routes
} from "react-router-dom";
import { connect } from "react-redux";

import { createBrowserHistory } from "history";

import { isLoggedin, setViewMode } from "./store/actions/authActions";

import configureAxios from "./store/utils/configureAxios";
import HomePage from "./pages/HomePage.jsx";
import LoginPage
	from "./pages/auth/LoginPage";
import AuthChecking
	from "./pages/authChecking";
import RegistrationPage
	from "./pages/auth/RegistrationPage";
import SwitchCompany
	from "./pages/company/SwitchCompany";

const history = createBrowserHistory();

class MyRoutes extends React.Component {
	state = {
		user: "",
		viewMode: null,
		password: "",
		error: {},
	};
	async componentWillMount() {
		configureAxios(); // Run Basic Axios Configure
	}

	componentDidMount() {}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			JSON.stringify(prevProps.auth) !== JSON.stringify(this.props.auth)
		) {
			let viewAsAgent = localStorage.getItem("viewAsAgent");

			let { user } = this.props.auth;

			let viewMode;

			if (user && user.U_Type === "admin" && viewAsAgent === "1") {
				viewMode = "affiliate";
			} else if (user && user.U_Type === "admin") {
				viewMode = "admin";
			} else if (user && user.affiliateStatus === "1") {
				viewMode = "affiliate";
			} else if (user && user.businessAccount === "1") {
				viewMode = "business";
			}
			// this.setState({
			// 	viewMode,
			// });
			this.props.setViewMode(viewMode);
		}
	}

	render() {
		let { isAuthenticated } = this.props.auth;
		let { viewMode } = 'public';

		if(isAuthenticated){
			viewMode = 'user';
		}

			return (
				<BrowserRouter
					history={history}>

					{(() => {
						if(viewMode === "user"){
							return (
								<Routes>
									<Route
										path="/"
										exact={true}
										element={
											<HomePage/>
										}
									/>
									<Route
										path="/switch-company"
										exact={true}
										element={
											<SwitchCompany/>
										}
									/>
								</Routes>
							);
						}else{
							return (
								<Routes>
									<Route
										path="/login"
										exact={true}
										element={
											<LoginPage/>}/>
									<Route
										path="/registration"
										exact={true}
										element={
											<RegistrationPage/>}/>
									<Route
										path="/*"
										// exact={true}
										element={
											<AuthChecking/>}
									/>)
								</Routes>
							);
						}
					})()}

				</BrowserRouter>);
	}
}

// export default App;

const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedin,
	setViewMode,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyRoutes);
