import axios from "axios";

import { showSuccess, showError } from "../utils/configToast";
import { manageError } from "../utils/axiosResponseError";
import * as Types
	from "./types";


export const newCompanyAction = (user) => async (dispatch) => {
	let responseData;
	await axios
		.post("company", user)
		.then((res) => {
			responseData = res.data;

			dispatch({
				type: Types.COMPANIES_ADD,
				payload: responseData.data,
			});

			showSuccess('Company successfully created');
		}).catch((error) => {
			manageError(error);
			responseData =  false;
		});

	return responseData
};
