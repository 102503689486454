import { combineReducers } from "redux";
import pageDataReducer from "./pageDataReducer";
import authReducer from "./authReducer";
import taskReducer from "./taskReducer";
import timeLogReducer
	from "./timeLogReducer";
import userReducer
	from "./userReducer";

const rootReducer = combineReducers({
	pageData: pageDataReducer,
	auth: authReducer,
	user: userReducer,
	taskData: taskReducer,
	timeLogData: timeLogReducer
});

export default rootReducer;
