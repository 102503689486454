import React from 'react'
import { connect } from 'react-redux'
import "flatpickr/dist/themes/material_green.css";
import {newCompanyAction} from "../../store/actions/companyActions";

class NewCompanyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        }
    }

    changeHandler = async (event) => {
        let stateName, stateValue;
        if (event.target.type == 'checkbox') {
            stateName = event.target.name;
            stateValue = event.target.checked;
        } else {
            stateName = event.target.name;
            stateValue = event.target.value;
        }

        this.setState({
            [stateName]: stateValue
        }, () => {
            console.log('type', event.target.type);
            if (event.target.type === 'checkbox' || event.target.type === 'select-one') {
                this.submitHandler()
            }
        })
    }

    submitHandler = async (event) => {
        event.preventDefault()

        this.setState({
            dataLoading: true
        })

        const { name } = this.state;
        await this.props.newCompanyAction({ name });
        this.setState({
            name: '',
            dataLoading: false,
        })
    }

    componentDidMount() {}

    render() {
        let{className} = this.props
        const { name } = this.state;

        return (
            <div className={`update-time-log ${className}`}>
                <form
                    onSubmit={this.submitHandler}>

                    <div className="">
                        <label
                            htmlFor="name">Company name</label>
                        <input value={name} onChange={this.changeHandler} id="name" name="name" />
                    </div>
                    <div>
                        <input type="submit" value="Submit" />
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    timeLogData: state.timeLogData,
})
const mapDispatchToProps = ({
    newCompanyAction,
})
export default connect(mapStateToProps, mapDispatchToProps)(NewCompanyForm)