import * as Types from '../actions/types'

const init = {}

const pageDataReducer = ( state = init, action) => {
	switch(action.type) {

		case Types.SET_PAGE_DATA: {
			return action.payload.data
		}

		default: return state
	}
}

export default pageDataReducer