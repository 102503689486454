import axios from 'axios';
import {asyncLocalStorage} from "./asyncLocalStorage";

const configureAxios = async (token) => {

	axios.defaults.baseURL = 'https://api-task-planner.prismict.com/';
	// axios.defaults.baseURL = 'http://localhost:8087';

	// let authToken = await localStorage.getItem('authToken')
	await setAuthToken()
	await setCompanyID()
	return true
}


export const setAuthToken = async () => {
	let token = await asyncLocalStorage.getItem('authToken');
	if (token) {
		axios.defaults.headers.common['Authorization'] = token;
	} else {
		axios.defaults.headers.common['Authorization'] = '';
	}
	var clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
	clientTimezoneOffset = parseInt(clientTimezoneOffset);
	if (clientTimezoneOffset > 0) {
		clientTimezoneOffset = "-" + Math.abs(clientTimezoneOffset)
	} else {
		clientTimezoneOffset = Math.abs(clientTimezoneOffset)
	}
	axios.defaults.headers.common['X-timezone'] = clientTimezoneOffset;
}

export const setCompanyID = async () => {
	// let companyID = await localStorage.getItem('companyID')
	let companyID = await asyncLocalStorage.getItem('companyID');

	if (companyID) {
		axios.defaults.headers.common['X-companyID'] = companyID;
	}
	return true;
}

export default configureAxios
