import * as Types from "../actions/types";
import {TASK_FILTER_SET, TASK_GROUP_SET, TASK_SET_PARENT} from "../actions/types";

const init = {
	data: [],
	groupData: {},
	parentTask: [],
	pagination: {},
	error: {},
};

let taskReducer = (state = init, action) => {
	switch (action.type) {
		case Types.TASK_SET: {
			return {
				...state,
				data: action.payload.data,
				pagination: action.payload.pagination,
				error: {},
			};
		}
		case Types.TASK_CLEAR: {
			return init;
		}
		case Types.TASK_FILTER_SET: {
			let data = state.data
			if (Object.keys(state.data).length) {
				// data.unshift(action.payload.data);

				let newData = action.payload.data;
				data = [...data, ...newData]
			} else {
				data = action.payload.data;
			}

			// update state & return
			return {
				...state,
				data
			};
		}
		case Types.TASK_SET_PARENT: {
			let parentTask = state.parentTask
			let newParent = action.payload.parentTask

			// parentTask = parentTask.push('newParent');
			// parentTask.unshift('object');

			if(action.payload.forceSet){
				parentTask = action.payload.parentTask
			}else if (parentTask.indexOf(newParent) === -1) {
				parentTask.push(newParent);
			}

			return {
				...state,
				parentTask : parentTask
			};
		}

		case Types.TASK_ADD: {
			if (Object.keys(state.data).length) {
				if (action.payload.data) {
					state.data.unshift(action.payload.data);
				}
			} else {
				state.data = [action.payload.data];
			}

			return {
				...state,
			};
		}

		default:
			return state;
	}
};

export default taskReducer;
