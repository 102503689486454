import React from 'react'
import { connect } from 'react-redux'
import bodyClassController from "../../store/utils/bodyClassController";
import {
    retrieveTimeLogAction,
    startTaskAction
} from "../../store/actions/taskActions";
import {ViewExactDate} from "../../components/DateViewer";
import TimeAgo
    from "react-timeago";
import Modal from "react-modal";
import EditTimeLogForm
    from "./EditTimeLogForm";

const moment = require('moment-timezone');

class ShowTimeLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    startTask = async (taskID) => {
        this.setState({
            dataLoading: taskID.id
        })
        await this.props.startTaskAction({
            taskID: taskID.taskID
        });
        this.setState({
            dataLoading: false
        })
    }

    componentDidMount() {
    }

    openEditLogModal(data) {
        this.setState({
            EditLogModalIsOpen: data,
        });
    }
    closeEditLogModal() {
        this.setState({
            EditLogModalIsOpen: false,
        });
    }

    render() {
        let className = this.props.className;

        let {timeLogData, user} = this.props
        let allLog;

        let selectedCompany = {};
        if(user){
            selectedCompany = user.companies.selected
        }


        if(timeLogData && timeLogData.data){
            allLog = timeLogData.data.length?timeLogData.data:null
        }

        let {parentDetails, dataLoading} = this.state;

        let timeSpent;
        if(parentDetails && parseInt(parentDetails.timeSpent) > 0){
            let date = new Date(null);
            date.setSeconds(parentDetails.timeSpent); // specify value for SECONDS here
            timeSpent = date.toISOString().substr(11, 8);
        }


        return (
            <div className={`${className}`}>

                <table>
                    <tr>
                        <th className="task-title">Task title</th>
                        <th>Start on</th>
                        <th>Finished on</th>
                        <th className="text-right">Time spent</th>
                    </tr>
                        {
                            allLog?allLog.map((item, itemIndex) => {
                                let spentSeconds;
                                if(item.endOn){
                                    let now = moment(item.endOn); //Finish date
                                    let end = moment(item.startOn); // Start date
                                    let duration = moment.duration(now.diff(end));
                                    spentSeconds = duration.asSeconds()
                                }

                                let didChange = false;

                                if(itemIndex > 0 ){
                                    if(moment(Date.parse(item.startOn)).format("DD MM YYYY") !== moment(Date.parse(allLog[itemIndex-1].startOn)).format("DD MM YYYY") ){
                                        didChange = true;
                                    }
                                }else{
                                    didChange = true;
                                }


                                let timeSpent;
                                if(spentSeconds > 0){
                                    let date = new Date(null);
                                    date.setSeconds(spentSeconds); // specify value for SECONDS here
                                    timeSpent = date.toISOString().substr(11, 8);
                                }

                                return (
                                    <>
                                        {didChange ?
                                            <tr>
                                                <td colSpan="100%" className="text-center section-title">
                                                    <ViewExactDate
                                                        date={item.startOn}
                                                        format="MMM DD"
                                                    />
                                                </td>
                                            </tr>:""}

                                        <tr>
                                            <td>
                                                {item.taskTitle}
                                                <div>
                                                    { item.endOn? dataLoading === item.id
                                                        ? <i className="fas fa-spinner fa-spin"/>
                                                        : <i className="fas fa-play cursor-pointer start-again" title="Start again" onClick={ () => {this.startTask(item)}}/>
                                                    : "" }

                                                    {
                                                        selectedCompany.relationType === 1
                                                            ?<i className="fas fa-pencil-alt cursor-pointer start-again"
                                                               title="Edit log"
                                                               onClick={() => {
                                                                   this.openEditLogModal(item)
                                                               }}/>
                                                            :''
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <ViewExactDate
                                                    date={item.startOn}
                                                    format="hh:mm a"
                                                />
                                            </td>
                                            <td>
                                                { item.endOn
                                                    ? <ViewExactDate
                                                        date={item.endOn}
                                                        format="hh:mm a"
                                                    />
                                                    : 'running'
                                                }
                                            </td>
                                            <td className="text-right">{timeSpent? timeSpent
                                                :<TimeAgo date={item.startOn} suffix={"Started"} /> }</td>
                                        </tr>
                                    </>
                                );
                            }):<tr><td className="p-5 text-center text-warning" colSpan="100%">Log data not available</td></tr>
                        }
                </table>
                {EditLogModal(this)}
            </div>
        )

        // Modal section
        function EditLogModal(that) {
            let logData = that.state.EditLogModalIsOpen??{}
            return (
                <Modal
                    isOpen={that.state.EditLogModalIsOpen}
                    contentLabel="Example Modal"
                    className="custom-modal modal-xs col-md-5"
                >
                    <div className="basic-modal">
                        <div className="modal-header">
                            <div>
                                <h3>Update time log</h3>
                                <p>{logData.taskTitle}</p>
                            </div>
                            <button
                                className="btn close-modal"
                                type={`button`}
                                onClick={() => {
                                    that.closeEditLogModal();
                                }}
                            >
                                <i className="far fa-times-circle"/>
                            </button>
                        </div>
                        <div className="modal-content">
                            <EditTimeLogForm
                                logData={logData}
                                onComplete={() => {
                                    that.closeEditLogModal();
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            );
        }

    }
}

const mapStateToProps = state => ({
    user: state.user,
    auth: state.auth,
    timeLogData: state.timeLogData,
})
const mapDispatchToProps = ({
    bodyClassController,
    retrieveTimeLogAction,
    startTaskAction
})
export default connect(mapStateToProps, mapDispatchToProps)(ShowTimeLog)