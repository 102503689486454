import * as Types from "../actions/types";

const init = {
	data: [],
	error: {},
	companies: {
		data: [],
		selected: {},
		error: {},
	}
};

let userReducer = (state = init, action) => {
	switch (action.type) {
		case Types.USER_COMPANIES_GET: {
			return {
				...state,
				companies: {
					...state.companies,
					data: action.payload,
				},
			};
		}
		case Types.USER_COMPANIES_SELECTED: {
			return {
				...state,
				companies: {
					...state.companies,
					selected: action.payload,
				},
			};
		}
		case Types.COMPANIES_ADD: {
			if (Object.keys(state.companies.data).length) {
				if (action.payload) {
					state.companies.data.push(action.payload);
				}
			} else {
				state.companies.data = [action.payload];
			}

			return {
				...state,
			};
		}

		default:
			return state;
	}
};

export default userReducer;
