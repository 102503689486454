import React from 'react'
import { connect } from 'react-redux'
import bodyClassController from "../../store/utils/bodyClassController";
import {insertTask, setParentTaskAction} from "../../store/actions/taskActions";

class CreateNewTask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    changeHandler = async (event) => {
        let stateName, stateValue;
        if (event.target.type == 'checkbox') {
            stateName = event.target.name;
            stateValue = event.target.checked;
        } else {
            stateName = event.target.name;
            stateValue = event.target.value;
        }


        this.setState({
            [stateName]: stateValue
        })
    }

    submitHandler = async (event) => {
        if (event != null) {
            event.preventDefault()
        }

        this.setState({
            dataLoading: true
        })

        let { parent, title, description } = this.state

        await this.props.insertTask({
            parent,
            title,
            description: "description"
        })
        this.resetFrom()

        this.setState({
            dataLoading: false
        })
    }

    resetFrom = () => {
        this.setState({
            title:""
        })
    }

    componentDidMount() {
        const input = document.querySelector("input.task-title");
        input.focus();
        input.select();

        this.setState({
            parent: this.props.parentTaskID
        })
    }

    componentWilMount() {
    }

    render() {
        return (
            <div className="task-create">
                <div className="details-header">
                    <h3 className="title">Create new task</h3>
                </div>
                <div className="create-body">
                    <form action="" onSubmit={this.submitHandler}>
                        <input type="text"
                               name="title"
                               onChange={this.changeHandler}
                               autoFocus={true}
                               value={this.state.title}
                               required={true}
                               className={`task-title`} placeholder={`Ex. Crate API`}/>
                        <input type="submit" value="Create"/>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
})
const mapDispatchToProps = ({
    bodyClassController,
    insertTask,
})
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTask)