import React from 'react'
import { connect } from 'react-redux'
import {
    retrieveTimeLogAction,
} from "../../store/actions/taskActions";
import "flatpickr/dist/themes/material_green.css";
import moment
    from "moment";
import {updateTimeLogAction} from "../../store/actions/timeLogActions";

class EditTimeLogForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        }
    }

    changeHandler = async (event) => {
        let stateName, stateValue;
        if (event.target.type == 'checkbox') {
            stateName = event.target.name;
            stateValue = event.target.checked;
        } else {
            stateName = event.target.name;
            stateValue = event.target.value;
        }

        this.setState({
            [stateName]: stateValue
        }, () => {
            console.log('type', event.target.type);
            if (event.target.type === 'checkbox' || event.target.type === 'select-one') {
                this.submitHandler()
            }
        })
    }

    updateTask = async (event) => {
        event.preventDefault()

        this.setState({
            dataLoading: true
        })

        const { logID, startOn, endOn } = this.state;
        await this.props.updateTimeLogAction({
            id: logID,
            startOn,
            endOn
        });
        this.setState({
            dataLoading: false
        })
    }

    componentDidMount() {
        let startOn = Date.parse(this.props.logData.startOn);
        startOn = moment(startOn).format("YYYY-MM-DDThh:mm");

        let endOn = this.props.logData.endOn;
        if(endOn) {
            endOn = Date.parse(endOn);
            endOn = moment(endOn).format("YYYY-MM-DDThh:mm");
        }

        this.setState({
            logID: this.props.logData.id,
            startOn: startOn,
            endOn: endOn
        })
    }

    render() {
        let{className} = this.props
        const { startOn, endOn } = this.state;

        return (
            <div className={`update-time-log ${className}`}>
                <form
                    onSubmit={this.updateTask}>

                    <div className="start-task">
                        <label
                                htmlFor="startOn">Start at</label>
                        <input type="datetime-local" value={startOn} onChange={this.changeHandler} id="startOn" name="startOn" />
                    </div>
                    <div className="end-task">
                        <label
                            htmlFor="endOn">Stop at</label>
                        <input type="datetime-local" value={endOn} onChange={this.changeHandler} id="endOn" name="endOn" />
                    </div>
                    <div>
                        <input type="submit" value="Update" />
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    timeLogData: state.timeLogData,
})
const mapDispatchToProps = ({
    retrieveTimeLogAction,
    updateTimeLogAction
})
export default connect(mapStateToProps, mapDispatchToProps)(EditTimeLogForm)