import React from "react";
import moment from "moment";


export class ViewExactDate extends React.Component {
	render() {
		let format = this.props.format ? this.props.format : "Do MMM LT";
		let datum = Date.parse(this.props.date);

		let exactTime = moment(datum).format(format);


		return <span className="date-exact-view">{exactTime}</span>;
	}
}