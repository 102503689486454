export const SET_USER = "SET_USER";
export const USERS_ERROR = "USERS_ERROR";
export const USERS_AUTH_CHECKING = "USERS_AUTH_CHECKING";

export const USER_RETRIEVE = "USER_RETRIEVE";
export const USER_DETAILS = "USER_DETAILS";
export const USER_SET_VIEW_MODE = "USER_SET_VIEW_MODE";
export const USER_COMPANIES_GET = "USER_COMPANIES_GET";
export const USER_COMPANIES_SELECTED = "USER_COMPANIES_SELECTED";

export const COMPANIES_ADD = "COMPANIES_ADD";

export const SET_PAGE_DATA = "SET_PAGE_DATA";

export const TASK_SET = "TASK_SET";
export const TASK_ADD = "TASK_ADD";
export const TASK_SET_PARENT = "TASK_SET_PARENT";
export const TASK_FILTER_SET = "TASK_FILTER_SET";
export const TASK_CLEAR = "TASK_CLEAR";

export const TIME_LOG_RETRIEVE = "TIME_LOG_RETRIEVE";
export const TIME_LOG_ADD = "TIME_LOG_ADD";
export const TIME_LOG_CLEAR = "TIME_LOG_CLEAR";