import React from 'react'
import { connect } from 'react-redux'
import bodyClassController from "../../store/utils/bodyClassController";
import {getTaskByID, retrieveTaskByParent, retrieveTaskMin, startTaskAction} from "../../store/actions/taskActions";
import CreateNewTask from "./CreateNewTask";

class ShowTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.escFunction = this.escFunction.bind(this);
    }

    resetFrom = () => {
        this.setState({
            title:""
        })
    }

    retrieveTasks  = async (parentTaskID) => {
        this.setState({
            dataLoading: parentTaskID
        })

        // let {parentTaskID, parentTaskIDTemp} = this.state
        // parentTaskID = parentTaskIDTemp ? parentTaskIDTemp :parentTaskID

        let taskState = this.props.taskData;
        await this.props.retrieveTaskByParent({
            parent: parentTaskID?parentTaskID != 'all'?parentTaskID:null:0
        }, taskState)

        this.setState({
            dataLoading: false
        })
    }

    escFunction = (event) => {
        const that = this;
        if(event.keyCode === 27) {
            that.setState({
                addTask: false
            })
        }
    }

    setParentData = async () => {
        let {parentTaskID, taskData} = this.props;
        let parentDetails = await this.props.getTaskByID(taskData, parentTaskID);

        this.setState({
            parentDetails:parentDetails
        })
    }

    startTask = async (taskID) => {
        this.setState({
            dataLoading:taskID
        })

        await this.props.startTaskAction({
            taskID
        });

        this.setState({
            dataLoading:false
        })
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

        if(this.props.parentTaskID === 'all') {
            // this.retrieveTasks(this.props.parentTaskID)
        }
        this.setState({
            parentTaskID: this.props.parentTaskID,
        }, () => {
            this.setParentData()
        })
    }

    componentWilMount() {
        this.setParentData()
    }

    render() {
        let className = this.props.className;

        let selectedCompany = {};

        let {taskData, user} = this.props
        let {parentTaskID, dataLoading} = this.state
        parentTaskID = parentTaskID ?parentTaskID:null
        let allTask, taskPagination, groupTask;

        if(user){
            selectedCompany = user.companies.selected
        }

        if(taskData){
            allTask = taskData.data.length?taskData.data:null
        }

        let {parentDetails} = this.state;

        let timeSpent;
        if(parentDetails && parseInt(parentDetails.timeSpent) > 0){
            let seconds = parentDetails.timeSpent;
            timeSpent = (Math.floor(seconds / 3600)) + ":" + ("0" + Math.floor(seconds / 60) % 60).slice(-2) + ":" + ("0" + seconds % 60).slice(-2);
        }

        let totalSubTask = 0;

        return (
            <div className={`parent-task ${className}`}>
                <div className="task-header">
                    { className === 'type-preview'?<i className="fas fa-arrow-left back"/>:'' }
                    <div className="header-title">
                        {parentDetails?parentDetails.title:'Main Task'}
                    </div>

                    {(() => {
                        if(parentDetails){
                            return <div className="header-actions">

                                {(() => {
                                    if(selectedCompany.relationType === 1){
                                        return <>
                                            <i className="fas fa-trash"/>
                                            <i className="fas fa-edit"/>
                                        </>
                                    }
                                    return ''
                                })()}

                                { dataLoading === parentTaskID
                                    ? <i className="fas fa-spinner fa-spin"/>
                                    : <i className="fas fa-play" onClick={ () => {this.startTask(parentTaskID)}}/>
                                }
                                {(() => {
                                    if(selectedCompany.relationType === 1){
                                        return <>
                                            {this.state.addTask
                                                ?<i className="fas fa-times" onClick={ () => {this.setState({addTask: false})} }/>
                                                :<i className="fas fa-plus" onClick={ () => {this.setState({addTask: true})} }/>}

                                            {this.state.addTask?<CreateNewTask parentTaskID={parentTaskID}/>:''}
                                        </>
                                    }
                                    return ''
                                })()}

                            </div>
                        }else if(selectedCompany.relationType === 1){
                            return <div
                                className="header-actions">
                                {this.state.addTask ?
                                    <i className="fas fa-times"
                                       onClick={() => {
                                           this.setState({addTask: false})
                                       }}/> :
                                    <i className="fas fa-plus"
                                       onClick={() => {
                                           this.setState({addTask: true})
                                       }}/>}

                                {this.state.addTask ?
                                    <CreateNewTask
                                        parentTaskID={parentTaskID}/> : ''}
                            </div>
                        }

                        return ''
                    })()}

                    <div className="header-summery">
                        {timeSpent ? <font className="text-muted">Spent: {timeSpent}</font>: '' }
                    </div>
                </div>
                <div className="task-list">
                    {
                        allTask?allTask.map((item) => {
                            if(parentTaskID == item.parent || parentTaskID == "all"){
                                totalSubTask = totalSubTask +1;
                                let timeSpent;
                                if(parseInt(item.timeSpent) > 0){
                                    let seconds = item.timeSpent;
                                    timeSpent = (Math.floor(seconds / 3600)) + ":" + ("0" + Math.floor(seconds / 60) % 60).slice(-2) + ":" + ("0" + seconds % 60).slice(-2);
                                }

                                return (<div className="single-task"
                                             id={`task_id_${item.id}`}
                                             onClick={() => {this.retrieveTasks(item.id)}}
                                             key={`${item.id}_${item.dateUpdated}`}>
                                    <div className="task-info">
                                        <div className="task-title">
                                            {item.title}
                                        </div>
                                        <div className="task-summery">
                                            {timeSpent ? <font className="text-muted">Spent: {timeSpent}</font>: '' }
                                        </div>
                                    </div>
                                    <div className="task-icon">
                                        {dataLoading === item.id
                                            ? <i className="fas fa-spinner fa-spin"/>
                                            : <i className="fas fa-angle-right"/>
                                        }
                                    </div>
                                </div>);
                            }
                        }):""
                    }
                    {
                        (!totalSubTask && selectedCompany.relationType === 1)
                            ? <div className="add-new-task" onClick={ () => {this.setState({addTask: true})} }><i className="fa-plus fa-plus-circle fas"/> {`  add new task`}</div>
                            :""
                    }
                </div>
                {/*<div className="task-actions">*/}
                {/*    <div className="single-action">*/}
                {/*        <div className="action-icon">*/}
                {/*            <i className="fas fa-trash"/>*/}
                {/*        </div>*/}
                {/*        <div className="action-title">Delete</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="task-footer">
                    <div className="single-item">
                        {`Total task ${totalSubTask}`}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    taskData: state.taskData,
})
const mapDispatchToProps = ({
    bodyClassController,
    retrieveTaskMin,
    getTaskByID,
    retrieveTaskByParent,
    startTaskAction
})
export default connect(mapStateToProps, mapDispatchToProps)(ShowTasks)