import React from 'react'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { login } from '../../store/actions/authActions'
import bodyClassController from '../../store/utils/bodyClassController'
import setPageData from '../../store/actions/setPageData'

// Style assets
import imgLogo from '../../assets/images/logo.png'
import {setUserCompany} from "../../store/actions/userActions";
import Modal
    from "react-modal";
import NewCompanyForm
    from "./NewCompanyForm";
import {useNavigate} from "react-router-dom";

class SwitchCompanyDtl extends React.Component {
    state = {
        user: '',
        password: '',
        remember: false,
        error: {},
        isLoading: false,
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if (JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)) {
            return {
                error: nextProps.auth.error
            }
        }
        return null
    }

    stopLoading = () => {
        this.setState({ isLoading: false })
    }

    submitHandler = event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        let { user, password, remember } = this.state
        this.props.login({ user, password, remember, type: 'moderator' }, this.props.history, this.stopLoading)
    }
    setCompany = async (item) => {
        await this.props.setUserCompany(item);
        this.props.navigate("/");
    }

    openNewCompanyModal() {
        this.setState({
            NewCompanyModalIsOpen: true,
        });
    }
    closeNewCompanyModal() {
        this.setState({
            NewCompanyModalIsOpen: false,
        });
    }

    componentWillMount() {
    }
    componentDidMount() {
        this.props.setPageData({
            title: 'Login',
            pageID: 'login'
        });
    }
    render() {
        this.props.bodyClassController(this.props.pageData);
        let {user} = this.props;

        console.log(user);

        let allCompanies;

        if(user && user.companies.data){
            allCompanies = user.companies.data.length?user.companies.data:null
        }

        return (
            <>
                <Helmet> <title>{'Switch company'}</title> </Helmet>

                <div className="container-fluid">
                    <div className="row page-auth">
                        <div className="col-md-4 page-content">
                            <div className="row">
                                <div className="col-md-12 login-content">
                                    <div className="auth-brand">
                                        <img src={imgLogo} className="logo" alt="Make SMM Panel" />
                                    </div>

                                    <p className="text-center">Select your company</p>
                                    <div className="text-right">
                                        {
                                            allCompanies?allCompanies.map((item) => {
                                                return (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            this.setCompany(item)
                                                        }}
                                                        className="btn btn-primary mb-2 px-5 btn-block">{item.name}</button>
                                                );
                                            }):<div>Company not found</div>
                                        }
                                        <span
                                            className="a"
                                            onClick={ () => {this.openNewCompanyModal()}}>Create new</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 preview-section">
                            <h1>TASK PLANNER</h1>
                            <p>Best way to utilize your time</p>
                        </div>
                    </div>
                </div>

                {NewCompanyModal(this)}
            </>
        )


        // Modal section
        function NewCompanyModal(that) {
            let logData = that.state.NewCompanyModalIsOpen??{}
            return (
                <Modal
                    isOpen={that.state.NewCompanyModalIsOpen}
                    contentLabel="Example Modal"
                    className="custom-modal modal-xs col-md-5"
                >
                    <div className="basic-modal">
                        <div className="modal-header">
                            <div>
                                <h3>Create new company</h3>
                            </div>
                            <button
                                className="btn close-modal"
                                type={`button`}
                                onClick={() => {
                                    that.closeNewCompanyModal();
                                }}
                            >
                                <i className="far fa-times-circle"/>
                            </button>
                        </div>
                        <div className="modal-content">
                            <NewCompanyForm
                                logData={logData}
                                onComplete={() => {
                                    that.closeNewCompanyModal();
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            );
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    auth: state.auth,
    pageData: state.pageData,
})

const mapDispatchToProps = ({
    login,
    setPageData,
    setUserCompany,
    bodyClassController
})

function SwitchCompany(props) {
    let navigate = useNavigate();
    return <SwitchCompanyDtl {...props} navigate={navigate} />
}
export default connect(mapStateToProps, mapDispatchToProps)(SwitchCompany)
