import axios from "axios";
import * as Types from "./types";

import { showSuccess, showError } from "../utils/configToast";
import { manageError } from "../utils/axiosResponseError";
import {retrieveTimeLog} from "./timeLogActions";
import {TASK_CLEAR} from "./types";


export const getTaskByID =
	(taskState, taskID) => async () => {
		let selectedTask;
		let {data:taskData} = taskState
		taskData && await taskData.map((s, index) => {
			if (s.id == taskID) {
				selectedTask = s
			}
		})
		return selectedTask
	};

export const retrieveTask =
	(postData) => (dispatch) => {
		axios
			.get("/task", {params: postData})
			.then((res) => {
				let responseData = res.data;
				// dispatch({
				// 	type: Types.TASK_SET,
				// 	payload: {
				// 		data: responseData.data,
				// 		pagination: responseData.pagination,
				// 		error: {},
				// 	},
				// });
				if(responseData.data){
					// dispatch({
					// 	type: Types.TASK_SET_PARENT,
					// 	payload: {
					// 		parentTask: responseData.data[0]
					// 	},
					// });
				}
			})
			.catch((error) => {
				manageError(error);
			});
	};

export const clearTask = (postData) => (dispatch) => {
	dispatch({
		type: Types.TASK_CLEAR,
		payload: {},
	});
	};

export const retrieveTaskByParent =
	(postData, taskState) => async (dispatch) => {
		await axios
			.get("/task", {params: postData})
			.then(async (res) => {
				let taskList = [];
				let responseData = res.data;

				// verify if already exists
				if (responseData.data) {
					responseData.data.map((i) => {
						let oldID = taskState.data.map(function (m) {
							return m.id;
						}).indexOf(i.id);

						if (oldID === -1) {
							taskList.push(i);
						} else {
						}
					})
				}

				dispatch({
					type: Types.TASK_FILTER_SET,
					payload: {
						data: taskList,
						parent: postData.parent,
						pagination: responseData.pagination,
						error: {},
					},
				});

				let parentTask = postData.parent
				if (!parentTask) {
					parentTask = 0
				}
				// get parent task details
				let parentDetails;
				if (parentTask !== 0) {
					let {data: taskData} = taskState
					taskData && await taskData.map((s, index) => {
						if (s.id == parentTask) {
							// s.parent = s.parent?s.parent:0
							parentDetails = s
						}
					})
				}
				// End of get parent details


				//
				let parentTaskList = taskState.parentTask;

				// Inset new parent of not exists
				if (parentTask === 0) {
					parentTaskList = [0]
				} else if (parentDetails && (!parentDetails.parent)) {
					parentTaskList = [parentTask]
				} else if (parentTaskList.indexOf(parentTask) === -1) {
					// check if new one parent and old items parent is same
					let {data: taskData} = taskState
					parentTaskList.map(async (tid) => {
						taskData && await taskData.map((s, index) => {
							if (s.id == tid) {
								if (s.parent == parentDetails.parent) {
									// remove old one
									let oldID = parentTaskList.indexOf(tid);
									if (oldID !== -1) {
										// delete parentTaskList[rootIndex]
										parentTaskList.splice(oldID, 1);
									}
								}
							}
						})
					})
					parentTaskList.push(parentTask);
				}


				// Remove root "0"
				if (parentTaskList.length > 1) {
					let rootIndex = parentTaskList.indexOf(0);
					if (rootIndex !== -1) {
						parentTaskList.splice(rootIndex, 1);
					}
				}

				// Remove if parent not exists in parentTaskList
				if (parentTaskList) {
					let deletedParent = [];

					// parentTaskList.map(async (p) => {
					for (const p of parentTaskList) {
						let tempParentDetails;
						// Get parent task details
						let {data: taskData} = taskState
						taskData && await taskData.map((s, index) => {
							if (s.id == p) {
								tempParentDetails = s
							}
						})

						if (tempParentDetails && tempParentDetails.parent && (p !== 0 || p !== null)) {
							// if(tempParentDetails && !tempParentDetails.parent){
							let tempParent = tempParentDetails.parent;
							tempParent = tempParent ? tempParent : 0;
							let ri = parentTaskList.indexOf(tempParent);

							let isParentDeleted = deletedParent.indexOf(tempParent);


							console.group()
							console.log(`Cur parent: ${p}`)
							console.log(`Cur Grand: ${tempParent}`)
							console.log(ri)
							console.groupEnd();

							//  || isParentDeleted !== -1

							let notRemove = true;


							if (ri === -1 || isParentDeleted !== -1) {
								notRemove = false
							} else if (tempParent !== 0) {
								// notRemove = false
							}

							if (notRemove === false) {
								let p_ = parentTaskList.indexOf(p);
								parentTaskList.splice(p_, 1);
								deletedParent.push(p);
							}
							console.group('parents')
							console.log(parentTaskList)
							console.groupEnd();
						}
					}
				}

				dispatch({
					type: Types.TASK_SET_PARENT,
					payload: {
						parentTask: parentTaskList,
						forceSet: 1
					},
				});
			})
			.catch((error) => {
				manageError(error);
			});
	};

export const retrieveTaskMin =
	(postData) => (dispatch) => {
		let responseData
		axios
			.get("/task", {params: postData})
			.then((res) => {
				responseData = res.data.data;
			})
			.catch((error) => {
				manageError(error);
			});

		return responseData
	};

export const setParentTaskAction =
	(parentTask) => (dispatch) => {
		// dispatch({
		// 	type: Types.TASK_SET_PARENT,
		// 	payload: {
		// 		parentTask
		// 	},
		// });
	};

export const insertTask =
	(postData) => (dispatch) => {
		axios
			.post("/task", postData)
			.then((res) => {
				let responseData = res.data;

				dispatch({
					type: Types.TASK_ADD,
					payload: {
						data: responseData.data,
						error: {},
					},
				});
			})
			.catch((error) => {
				manageError(error);
			});
	};



export const startTaskAction = (postData) => async (dispatch) => {
	await axios
		.post("/task/start", postData)
		.then(async (res) => {

			await dispatch(retrieveTimeLog());

			showSuccess('Task started');
		})
		.catch((error) => {
			manageError(error);
		});
};

export const startTaskAction_ =
	(postData) => async (dispatch) => {
		await axios
			.post("/task/start", postData)
			.then((res) => {
				// let responseData = res.data;

				// eslint-disable-next-line no-unused-expressions

				 dispatch(retrieveTimeLog());


				showSuccess('Task started');
			})
			.catch((error) => {
				manageError(error);
			});
		return true;
	};


export  const getTaskByID_ =  (taskState, taskID) => {
	let selectedTask;
	let {taskData} = taskState

	// const totalPackages = productInfo.productPackages.length
	// taskData && await taskData.map((s, index) => {
	// 	if (s.id == taskID) {
	// 		selectedTask = s
	// 	}
	// })

	return selectedTask
}



export const retrieveTimeLogAction =
	(postData) => async (dispatch) => {
		let returnData;
		await axios
			.get("/time-log", {params: postData})
			.then((res) => {
				returnData = res.data;
			})
			.catch((error) => {
				manageError(error);
			});
		return returnData;
	};
